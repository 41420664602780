import {
  Button, Typography, AlertTitle, Snackbar,
  Slide,
  Alert
} from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { moment } from '../utils/date';

export const UserInformationSnackbar = (props) => {
  // const { ...other } = props;

  const [displaySnackBar, setDisplaySnackBar] = useState(true);
  const [displayGdpr, setDisplayGdpr] = useState(true);
  const { user, login } = useAuth();

  useEffect(() => {
    // Restore state from local/session storage
    if (user.snackbarDismiss === true) {
      setDisplaySnackBar(false);
    }
    if (user.gdprDismiss === true) {
      setDisplayGdpr(false);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const handleDismissSnackbar = async () => {
    if (displaySnackBar) {
      setDisplaySnackBar(false);
      try {
        await axios.post('/api/user/dismiss-snackbar');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDismissGdpr = async () => {
    if (displayGdpr) {
      setDisplayGdpr(false);
      try {
        await axios.post('/api/user/dismiss-gdpr');
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleLogin = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
      toast.error('Impossible de se reconnecter');
    }
  };

  return (
    <>
      {
        displayGdpr &&
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={displayGdpr}
          TransitionComponent={(TransitionProps) => (<Slide {...TransitionProps} direction="up" />)}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            <Typography variant="h4" gutterBottom>
              Règlement Général de Protection des Données
              <br />et protection des Informations Commercialement Sensibles
            </Typography>
            <Typography variant="body1" paragraph>
              Vous devez respecter les règles suivantes :
              <br />La non divulgation d'informations à des tiers non autorisés (notamment à travers les fichiers d'export)
              <br />La saisie d'informations objectives, nécessaire et suffisantes au traitement
              <br />Les informations saisies doivent être licites, adéquates, pertinentes et non excessives au regard du contexte.
              <br />Elles ne doivent comporter aucune appréciation sur le comportement ou les traits de caractère et aucun jugement de valeur, ni faire apparaître, directement ou indirectement, des informations relatives aux origines raciales ou ethniques, opinions politiques, philosophiques ou religieuses, à l’appartenance syndicale, à la santé, à la vie sexuelle ainsi qu’aux sanctions et condamnations de toute personne.
            </Typography>

            <Button
              color="primary"
              size="small"
              onClick={handleDismissGdpr}
            >
              Ne plus afficher ce message
            </Button>
          </Alert >
        </Snackbar >
      }
      {
        false && displaySnackBar && <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={displaySnackBar}
          TransitionComponent={(TransitionProps) => (<Slide {...TransitionProps} direction="up" />)}
        >
          <Alert severity="warning" sx={{ width: "100%" }}>
            <AlertTitle>{moment('2024-12-27T14:00:00').fromNow()}</AlertTitle>
            Les robots CUAU et OSR sont réparés.
            {/* Afin de corriger des bogues, j'ai rattaché manuellement vos dossiers.
            En cas de problème dans l'affichage, reconnectez vous :
            <Button
              color="secondary"
              size="small"
              onClick={handleLogin}
            >
              Reconnecter
            </Button>

            <br />Si le problème persiste contactez moi, je corrigerai l'affectation :
            <Button
              color="secondary"
              size="small"
              href="https://teams.microsoft.com/l/chat/0/0?users=mathieu-m.fernandes%40enedis.fr"
            >
              {'Contactez moi'}
            </Button> */}
            <br /><Button
              color="primary"
              size="small"
              onClick={handleDismissSnackbar}
            >
              Ne plus afficher ce message
            </Button>
          </Alert>
        </Snackbar>
      }
    </>
  );
};
